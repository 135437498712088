// AUTHENTICATION

// USER REGISTER AUTH
export const USER_REGISTER = "USER_REGISTER";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_ERROR = "USER_REGISTER_ERROR";
// USER LOGIN AUTH
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
// USER LOGOUT AUTH
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_ERROR = "USER_LOGOUT_ERROR";
// FORGET PASSWORD AUTH
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR";
// RESET PASSWORD AUTH
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";



// CHAT WITH RAG
export const CHAT_RAG = "CHAT_RAG";
export const CHAT_RAG_SUCCESS = "CHAT_RAG_SUCCESS";
export const CHAT_RAG_ERROR = "CHAT_RAG_ERROR";

// GET DASHBOARD PARAMS
export const GET_DASHBOARD_PARAMS = "GET_DASHBOARD_PARAMS";
export const GET_DASHBOARD_PARAMS_SUCCESS = "GET_DASHBOARD_PARAMS_SUCCESS";
export const GET_DASHBOARD_PARAMS_ERROR = "GET_DASHBOARD_PARAMS_ERROR";

// GET MACHINE LIST
export const GET_MACHINE_LIST = "GET_MACHINE_LIST";
export const GET_MACHINE_LIST_SUCCESS = "GET_MACHINE_LIST_SUCCESS";
export const GET_MACHINE_LIST_ERROR = "GET_MACHINE_LIST_ERROR";

// GET MACHINE DETAIL
export const GET_MACHINE_DETAIL = "GET_MACHINE_DETAIL";
export const GET_MACHINE_DETAIL_SUCCESS = "GET_MACHINE_DETAIL_SUCCESS";
export const GET_MACHINE_DETAIL_ERROR = "GET_MACHINE_DETAIL_ERROR";

// REPORT_LIST
export const REPORT_LIST = "REPORT_LIST";
export const ADD_REPORT_TO_LIST = "ADD_REPORT_TO_LIST";



